import '../../../../styles/composite/Header/Menu/HeaderLogo.scss';

import gravityLogo from '../../../../images/profile/gravity-logo.png';

const HeaderLogo = () => {
  return (
    <div className="HeaderLogo">
      <img
        className="codingLeagueLogo"
        src={gravityLogo}
        alt="Gravity Education Logo" />
    </div>
  );
}

export default HeaderLogo;
