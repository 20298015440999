import { useContext } from 'react';
import { Context } from '../../DataStore';
import strings from '../../data/strings';
import profiles from '../../data/profiles';
import sales from '../../data/sales';

import SectionTitle from '../common/SectionTitle';
import Profile from './About/Profile';

import '../../styles/composite/OurTeam.scss';

const OurTeam = () => {
  const { store } = useContext(Context);
  const text = strings[store.lang].OurTeam;

  return (
    <div id={text.header.replace(' ', '')} className="OurTeam">
      <SectionTitle text={text.header} />

      <div className="contentWrapper">
        {text.description}
      </div>

      <div className="profileWrapper engineers">
        {profiles[store.lang].map((profile, i) => (
          <Profile
            key={i}
            data={profile}
            type="engineer" />
        ))}
      </div>

      <SectionTitle text="Partners" />

      <div className="profileWrapper sales">
        {sales.people[store.lang].map((profile, i) => (
          <Profile
            key={i}
            data={profile}
            type="sales" />
        ))}
      </div>
    </div>
  );
}

export default OurTeam;
