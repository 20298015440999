import '../../styles/common/SectionTitle.scss';

const SectionTitle = (props) => {
  return (
    <div className="SectionTitle">
      <div className="title">
        {props.text}
      </div>
    </div>
  );
}

export default SectionTitle;
