const strings = {
  en: {
    Header: {
      header: 'Home',
      MenuItems: ["Home", "Our Team", "Testimonials", "Contact"],
      HeaderText: {
        company: "GRAVITY EDUCATION",
        tag: "Every Student. Uniquely You.",
        description: "A multifaceted educational consulting firm focussed on every aspect from childhood and teenage skill training, application guidance from middle school to PhDs as well as unique life coach and psychology wellbeing for all."
      }
    },
    Video: {
      header: "Introduction"
    },
    OurTeam: {
      header: "Our Team",
      description: "Our team spans China, the United States, Australia, the United Kingdom, France, Singapore, and Canada. They are all successful individuals in their fields, compassionate and, most importantly, care for each and every child's emotional and educational growth. we are honored to have this hand-selected team as our advisors and teachers."
    },
    Methodology: {
      header: "Testimonials"
    },
    Hackathons: {
      header: "Contact"
    }
  },
  zh: {
    Header: {
      header: '团队',
      MenuItems: ["主页", "团队", "学生感言", "联系方式"],
      HeaderText: {
        company: "万有引力",
        tag: "每个学生都是 独一无二的",
        description: "一家多元化的教育咨询公司，专注于从儿童和青少年英语培训、从中学到博士的学校申请指导以及独特的生活教练和心理健康咨询服务。"
      }
    },
    Video: {
      header: "介绍"
    },
    OurTeam: {
      header: "团队",
      description: "我们的团队遍布中国、美国、澳大利亚、英国、法国、新加坡和加拿大。 他们都是各自领域的成功人士，富有同情心，最重要的是，他们关心每个孩子的情感和教育成长。 我们很荣幸拥有团队作为我们的顾问和老师。"
    },
    Methodology: {
      header: "学生感言"
    },
    Hackathons: {
      header: "联系方式",
    }
  }
};

export default strings;