import { useContext } from 'react';
import { Context } from '../../DataStore';
import strings from '../../data/strings';

import Menu from './Header/Menu';
import HeaderText from './Header/HeaderText';

import '../../styles/composite/Header.scss';

const Header = () => {
  const { store } = useContext(Context);

  return (
    <div id={strings[store.lang].Header.header} className="Header">
      <div className="headerFade" />
      <Menu />
      <HeaderText />
    </div>
  );
}

export default Header;
