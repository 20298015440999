import { useContext } from 'react';
import { Context } from '../../../DataStore';
import strings from '../../../data/strings';

import '../../../styles/composite/Header/HeaderText.scss';

const HeaderText = () => {
  const { store } = useContext(Context);
  const text = strings[store.lang].Header.HeaderText;

  return (
    <div className="headerText">
      <div className="textTitle">
        {text.company}
      </div>
      <div className="textSubtitle">
        {text.tag}
      </div>
      <div className="textText">
        {text.description}
      </div>
    </div>
  );
}

export default HeaderText;
