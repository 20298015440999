import '../../../styles/composite/Header/Menu.scss';

import HeaderLogo from './Menu/HeaderLogo';
import MenuItems from './Menu/MenuItems';

const Menu = () => {
  return (
    <div className="Menu">
      <HeaderLogo />
      <MenuItems />
    </div>
  );
}

export default Menu;
