import markAvatar from '../images/profile/mark.jpg';
import sharonAvatar from '../images/profile/sharon.jpg';

import newvisionLogo from '../images/png/newvision.png';

const sales = {
	people: {
		en: [
			{
				name: 'Mark',
				image: markAvatar,
				company: 'New Vision Education',
				logo: newvisionLogo,
				title: 'Exclusive Partner (China)',
				qualifications: [
					'Certified Professional Manager, The Society of Business Practitioners, England',
					'Postgraduate Diploma in Business Administration',
					'British Business Professionals Association - Registered Professional Managers, MBA'
				],
				bio: 'Mark is a senior international education business operator and manager. With more than 20 years of industry management experience, he has served as a senior executive of a Chinese publicly-listed education group and the first generation of an American education group in China.',
				quote: 'Programming will become the universal language of the future and we hope to help you become a citizen of this world.',
				designation: false
			},
			{
				name: 'Sharon',
				image: sharonAvatar,
				company: 'New Vision Education',
				logo: newvisionLogo,
				title: 'Exclusive Partner (China)',
				qualifications: [
					'Enrolled in Master of Education Program',
					'Innovative Education Practitioner'
				],
				bio: 'Sharon previously served as the Director of Operations for an educational technology company in the United States. Before entering the field of international education, Sharon worked for various Top 500 European and American companies and accumulated rich transnational cultural thinking and management experience. Out of her love for education, she entered the international education field in 2008, committed to the implementation of educational innovation.',
				quote: 'Innovation is the inheritance, refinement and breakthrough of the past, as is educational innovation.',
				designation: false
			}
		],
		zh: [
			{
				name: 'Mark',
				image: markAvatar,
				company: 'New Vision Education',
				logo: newvisionLogo,
				title: '独家合作伙伴（中国）',
				qualifications: [
					'Certified Professional Manager, The Society of Business Practitioners, England',
					'Postgraduate Diploma in Business Administration',
					'英国商业职业人协会- 注册职业经理人, 工商管理硕士'
				],
				bio: 'Mark 是资深国际教育业务运营和管理人. 拥有20多年行业管理经验, 曾任中国上市教育集团高管和美国教育集团中国区首代.',
				quote: '编程将成为未来世界的通用语言, 期望助力你成为世界公民.',
				designation: false
			},
			{
				name: 'Sharon',
				image: sharonAvatar,
				company: 'New Vision Education',
				logo: newvisionLogo,
				title: '独家合作伙伴（中国）',
				qualifications: [
					'教育学在读硕士',
					'创新教育实践者'
				],
				bio: 'Sharon曾任美国教育科技运营总监, 进入国际教育领域之前, Sharon老师任职于不同欧美500强企, 积累了丰富的跨国文化思维和管理经验, 出于对教育行业的热爱, 于2008年进入国际教育领域, 致力于推行教育创新.',
				quote: '创新是对过去的继承, 提炼和突破, 教育创新亦是如此.',
				designation: false
			}
		]
	}
}

export default sales;