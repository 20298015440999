import { useContext } from 'react';
import { Context } from '../../../../DataStore';
import strings from '../../../../data/strings';

import Link from '../../../common/Link';

import '../../../../styles/composite/Header/Menu/MenuItems.scss';

const MenuItems = () => {
  const { store, dispatch } = useContext(Context);

  const toggleLanguage = (language) => {
    dispatch({
      type: 'lang',
      data: language
    });
  }

  return (
    <div className="MenuItems">
      {strings[store.lang].Header.MenuItems.map((link, i) => {
        const isFilled = link === 'Apply' || link === '申请';
        return (
          <Link
            key={i}
            text={link}
            filled={isFilled} />
        )
      })}
      <div className={`languages ${store.lang}`}>
        <button
          className="language english"
          onClick={() => toggleLanguage('en')}>
          En
        </button>
        <button
          className="language chinese"
          onClick={() => toggleLanguage('zh')}>
          中文
        </button>
      </div>
    </div>
  );
}

export default MenuItems;
