import { useContext } from 'react';
import { Context } from '../../DataStore';
import strings from '../../data/strings';

import SectionTitle from '../common/SectionTitle';

import QRCode from '../../images/profile/qrcode.jpg';
import WeChat from '../../images/profile/wechat.png';
import Email from '../../images/profile/email.png';

import '../../styles/composite/Hackathons.scss';

const Hackathons = () => {
  const { store } = useContext(Context);
  const text = strings[store.lang].Hackathons;

  return (
    <div id={text.header.replace(' ', '')} className="Hackathons">
      <SectionTitle text={text.header} />
      <div className="head">
        <div className="double image">
          <img className="wechat" src={Email} alt="Email address" />
        </div>
        <div className="double title">
          Email Address
        </div>
        <div className="email">
          <a 
            className="address" 
            href="mailto:brightvillage2017@gmail.com">
            brightvillage2017@gmail.com
          </a>
        </div>
      </div>


      <div className="head">
        <div className="double image">
          <img className="wechat" src={WeChat} alt="WeChat" />
        </div>
        <div className="double title">
          WeChat QR Code
        </div>
      </div>
      <img 
        className="QRCode" 
        src={QRCode} 
        alt="WeChat QR Code" />

    </div>
  );
}

export default Hackathons;
