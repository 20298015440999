import '../../styles/common/Portrait.scss';

const Portrait = (props) => {
  return (
    <div className="Portrait">
      <img
        className="image"
        src={props.image}
        alt={props.name} />
    </div>
  );
}

export default Portrait;
