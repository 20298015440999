import { useReducer } from 'react';
import { Context, state, reducer } from './DataStore';

import Header from './components/composite/Header';
import OurTeam from './components/composite/OurTeam';
import Methodology from './components/composite/Methodology';
import Hackathons from './components/composite/Hackathons';
import Footer from './components/composite/Footer';
import Video from './components/composite/Video';

import './App.scss';

const App = () => {
  const [store, dispatch] = useReducer(reducer, state);

  return (
    <Context.Provider value={{ store, dispatch }}>

      <div className="App">
        <Header />
        <Video />
        <OurTeam />
        <Methodology />
        <Hackathons />
        <Footer />
      </div>

    </Context.Provider>
  );
}

export default App;
