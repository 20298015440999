import '../../../styles/composite/About/Profile.scss';

import ReactHtmlParser from 'react-html-parser'; 
import Portrait from '../../common/Portrait';

const Profile = (props) => {
  return (
    <div className="Profile">
      <Portrait
        image={props.data.image}
        name={props.data.name}
        logo={props.data.logo}
        company={props.data.company} />

      <div className="name">
        {props.data.name}
      </div>
      {props.data.designation &&
      <div className={`title${props.data.designation ? ' designation' : ''}`}>
        <span className="titleText">{props.data.title}</span>
        <span className="titleCompany">Gravity Education</span>
      </div>
      }

      {props.data.qualifications &&
        <div className="qualifications">
          {props.data.qualifications.map((qualification, i) => (
            <div
              key={i}
              className="qualification">
              {ReactHtmlParser(qualification)}
            </div>
          ))}
        </div>
      }

      <div className="bio">
        {ReactHtmlParser(props.data.bio) ?? ''}
      </div>
    </div>
  );
}

export default Profile;
