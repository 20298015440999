import { useContext } from 'react';
import { Context } from '../../DataStore';
import strings from '../../data/strings';

import SectionTitle from '../common/SectionTitle';
import '../../styles/composite/Video.scss';

const Video = () => {
    const { store } = useContext(Context);
    const text = strings[store.lang].Video;

  return (
    <div className="Video">
      <SectionTitle text={text.header} />

      <video 
        className="videoIntro" 
        src={`https://gravity-media.s3.amazonaws.com/Gravity+Education+Promo+2022+${store.lang === 'zh' ? 'Chi' : 'Eng'}.mp4`}
        controls />s
    </div>
  );
}

export default Video;
