import '../../styles/composite/Footer.scss';

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="Footer">
      <div className="footerText">
        &copy;{` Copyright ${year} - Swagalicious, LLC - All Rights Reserved `}
      </div>

    </div>
  );
}

export default Footer;
