import defaultAvatar from '../images/profile/default.png';

import jessicaAvatar from '../images/profile/jessica.jpg';
import macAvatar from '../images/profile/mac.jpg';
import susanAvatar from '../images/profile/susan.jpg';
import erinnAvatar from '../images/profile/erinn.jpg';
import lenaAvatar from '../images/profile/lena.png';
import marinaAvatar from '../images/profile/marina.png';
import cathyAvatar from '../images/profile/cathy.jpg';
import clarkAvatar from '../images/profile/clark.jpg';
import danielAvatar from '../images/profile/daniel.jpg';
import nickAvatar from '../images/profile/nick.jpg';
import salomonAvatar from '../images/profile/salomon.jpg';
import teddyAvatar from '../images/profile/teddy.jpg';
import juliaAvatar from '../images/profile/julia.jpg';
import jadeAvatar from '../images/profile/jade.jpg';
import jenniferAvatar from '../images/profile/jennifer.jpg';
import ameliaAvatar from '../images/profile/amelia.jpg';

const profiles = {
  en: [
    {
      name: 'Jessica',
      image: jessicaAvatar,
      title: 'Founder',
      designation: true,
      qualifications: [
        'Founder of Gravity Education Consulting Company',
        'Co-Founder of Gravity Education Technology',
        'Johns Hopkins University M.Ed.; M.S. Sociology; Ph.D. in Psychology'
      ],
      bio: `
      During more than a decade of English education and study abroad planning, I have met so many lovely students. But at the same time, I have also learned of their various troubles. I often think that "cultivating a person is cultivating their hope for the future". So as an educator, in addition to teaching knowledge and skills, we should also pay attention to students' future plans and especially their mental health.<br>
      <br>
      In addition to students, I have also encountered many parents who are quite anxious at the same time. Getting too close will make your child resist, but letting go will make you uneasy. Sometimes, parents also need to be cared for and reassured.<br>
      <br>
      Therefore, we have assembled a collective of artists, teachers, professors, education experts, business executives and psychology experts. Our team spans China, the United States, Australia, the United Kingdom, France, Singapore, and Canada. We ensure that every student who comes to Gravity, whether for English learning for children and teenagers, study abroad guidance, interview training, vocational training, educational path design, inner growth or career prospects can get the most professional and comprehensive service. We ensure that every parent who comes to Gravity can get the most timely feedback, hear the most advanced educational concepts and walk hand in hand with their child to grow together.
      `
    },
    {
      name: 'Mr. Mac',
      image: macAvatar,
      qualifications: [
        'Famous British/American Artist',
        'Founder of Shadow Art Genre',
        'Pollock/Krasner Foundation Award Winners',
        'Distinguished Teaching Professor, State University of New York'
      ],
      bio: `Art pieces have been acquired by more than 35 museums, including the New York Museum of Modern Art (MoMA), the Pompidou Center, the Paris Museum of Art, the Luxembourg Museum of Modern Art and more.<br>
      Loves to learn about new art trends and has recently released an NFT collection.
      `
    },
    {
      name: 'Ms. Susan',
      image: susanAvatar,
      qualifications: [
        '20+ IB Primary Years Programme (IB PYP) childhood education',
       '15+ years teaching other teachers'
      ],
      bio: `
       Ms. Susan has over 20 years of experience teaching and coaching other teachers in IB PYP methodology and inquiry-based learning. In our children's English training classes, we use  her methods and course materials to empower your child to learn more efficiently than ever.
      `
    },
    {
      name: 'Ms. Erinn',
      image: erinnAvatar,
      qualifications: [
        '30+ years of English/Art/Geography childhood education',
       'American Home School Cooperative Teacher and Principal',
       'Bachelor of Early Childhood Education, M.S. Education'
      ],
      bio: `
       Ms. Erinn teaches utilizing a scientific and vivid method combined with her rich experience in online teaching. Having taught thousands of children, she can effectively improve childrens' concentration and knowledge acquistion through digital means.<br>
       Skilled at using a unique self-created teaching system that skillfully combines English, grammar and geographical knowledge to provide children with the most valuable courses in all aspects.
      `
    },
    {
      name: 'Ms. Lena',
      image: lenaAvatar,
      qualifications: [
        '12+ years of experience in teaching children and adolescents',
        'American Public School Teacher',
        'Bachelor of Childhood Education and Dramatic Arts, M.Ed'
      ],
      bio: `
      Ms. Lena is a master at using props, sound imitation and other methods to help students deeply memorize content. In her preschool-level teaching, she utilizes a "music" teaching method constructed over more than a decade of teaching that enables children to learn important content by heart while being entertained. In her primary school teaching, she masterfully uses examples, discussions, feedback and evaluation methods to guide childrens' critical thinking and brings to life any content in a scientific way.
      `
    },
    {
      name: 'Mr. John',
      image: defaultAvatar,
      qualifications: [
        'Co-Founder of Right Direction Education Group',
        'CPO of Code Legend Vocational Training Company',
        'EdTech Investor',
        'Former Admissions Officer of US Private University',
        'B.A. Education, Vanderbilt University, M.S. Communication/Management'
      ],
      bio: `
      With more than 30 years of experience as a leader in the education and study abroad fields, he is highly skilled in consulting on "difficult" study abroad applications. He is good at identifying problems in a straight-forward manner, helping students design the most scientific educational route, establishing application methods and future career planning.
      `
    },
    {
      name: 'Ms. Marina',
      image: marinaAvatar,
      qualifications: [
        'Honorary Staff of Harvard University',
        'Associate Director of Research, Harvard School of Mechanical Engineering and Technology',
        'Financial Manager, Health Insurance Division, Harvard Medical School',
        'Manager of Research Award Management, School of Economics, Boston University'
      ],
      bio: `
      `
    },
    {
      name: 'Ms. Cathy',
      image: cathyAvatar,
      qualifications: [
        'Renowned American Educator and Artist',
        'Fulbright Senior Research Scholar',
        'Professor at Montclair State University'
      ],
      bio: `
      Works have been exhibited multiple times internationally<br>
      Currated a global tour exhibition project of works, organized in cooperation with Shanghai, Japan, Canada and more
      `
    },
    {
      name: 'Mr. Clark',
      image: clarkAvatar,
      qualifications: [
        'Founder of SAT classes at "Learners Academy" in New York',
        'Consultant at Enchanted College of Tutoring in Toronto',
        'B.A. in English, M.Ed, University of Manchester, UK'
      ],
      bio: `
      Uses original SAT and ACT teaching methodology to help students self-discover problems and improve their scores. Over 30+ years of teaching English, he has taught thousands of students and the average grade has increased by 70%. At the same time, he is good at advanced English writing and analysis of English and American literature. He uses humorous and vivid language and clear and accurate explanations to help students effectively improve their in-depth understanding of English.
      `
    },
    {
      name: 'Mr. Daniel',
      image: danielAvatar,
      qualifications: [
        'Founder of Gravity Education Technology',
        'CEO of Swaglicious Technologies',
        'Former Visiting Professor, SUNY Art Department',
        'CTO of SI Senior Talent Management Company',
        'Solar Resources Corporation Technology Consultant'
      ],
      bio: `
      From a visual arts major to computer programming and then transitioning to technology senior management, every transition was inseparable from excellent long-term career planning. Therefore, as a Gravity career planning consultant, Daniel provides students with accurate career skill analysis and future career development route design. As an alumnus of Cornell University, he often volunteers to answer questions about Ivy League schools for students, such as campus culture, safety, teaching style, etc.
      `
    },
    {
      name: 'Mr. Nick',
      image: nickAvatar,
      qualifications: [
        'UC Berkeley Haas School of Business Dual Degree in Business Administration and Computer Science',
        'UIUC M.S. Computer Science',
        'Former Senior Executive at Berkeley Consulting',
        'Senior Engineer at Amazon'
      ],
      bio: `
      `
    },
    {
      name: 'Mr. Salomon',
      image: salomonAvatar,
      qualifications: [
        'University of Pennsylvania Dual Degree in Biology and Chemistry',
        'Lectured at NYU, UPenn',
        'Former Penn Fellow',
        'Washington, D.C. Department of Education, Childrens Behavioral',
        'Published the book, "Opportunity Index Injustice and Environmental Injustice"',
        'Senior Data Analyst at Morning Consult'
      ],
      bio: `
      `
    },
    {
      name: 'Mr. Teddy',
      image: teddyAvatar,
      qualifications: [
        'Outstanding Graduate of Trinity School',
        'Brown University Honors Graduate B.S., Computer Science',
        'Young Philanthropist',
        'Amazon Senior Engineer'
      ],
      bio:`
      `
    },
    {
      name: 'Ms. Julia',
      image: juliaAvatar,
      qualifications: [
        'Former Senior Project Manager, Bank of America',
        'UNSW MBA',
        'Bachelor of Economics, Macquarie University, Australia'
      ],
      bio: `
      The learning ability and rigorous attitude cultivated from the High School Affiliated to Renmin University of China enabled Mr. Julia the ability to benefit from both work and teaching for a lifetime. Skilled at tutoring in mathematics, economics, and finance-related courses, with clear explanations and unique methods, she allows students to quickly understand complex methods. She specializes in Australian university applications, science-related study plans, international school studies and the application process.
      `
    },
    {
      name: 'Ms. Jade',
      image: jadeAvatar,
      qualifications: [
        '14+ years of sales operations and project management in multinational companies',
        'Fintech Project Manager',
        'Tsinghua University MBA PMP',
        'University of Lyon III Human Resource Management in France'
      ],
      bio: `
      Ms. Jade has deep industry experience in finance and management. She has worked and studied in Singapore, France, the United States and other countries, bringing students an international perspective and accurate professional career choice suggestions. At the same time, as a top scorer in TOEFL, she provides students with the most effective way to learn English. Ms. Jade insists on giving students the most direct, practical and honest answers and is a sharp tutor whom students admire.
      `
    },
    {
      name: 'Mr. Fengyuan',
      image: defaultAvatar,
      qualifications: [
        'University of Texas at Austin Honors Graduate in Finance',
        'Tufts University Masters Honors Graduate',
        'Former Scientific Journal Career Editor',
        'International Professional Manager'
      ],
      bio: `
      `
    },
    {
      name: 'Ms. Jennifer',
      image: jenniferAvatar,
      qualifications: [
        'New York District School Board Youth Counselor',
        'Certified Life Coach',
        'New York Primary and Secondary School Teacher Training Instructor'
      ],
      bio: `
      At work, Ms. Jennifer is familiar with the pressure of children and adolescents attending school, their interpersonal relationships, the complexities of classmate conflicts, life anxieties, cultural differences and various aspects that affect our youth. With a professional, scientific and friendly attitude, she help students get out of their mental dilemmas.
      `
    },
    {
      name: 'Ms. Amelia',
      image: ameliaAvatar,
      qualifications: [
        'Founder of "Xiaozhuo" Childrens Aesthetic Education Studio',
        'Master of Child Development and Educational Psychology, Chinese Academy of Sciences',
        'Life Coach International Certified Coach',
        'Painting Psychoanalysis and Healer'
      ],
      bio: `
       Ms. Amelia believes that every child is unique in their very own way<br>
       The talent of psychologists comes from the love of the profession<br>
       Love every moment with the kids<br>
       <br>
       She is not only a professional and rigorous consultant in the consulting room, but also a big sister trusted and loved by children. She is well versed at teaching children to draw, converse, laugh and have fun together, using professional knowledge to analyze children's psychology while helping each child establish an excellent learning attitude and healthy psychology. Timely detection of children's behavioral problems enables children to develop a strong ability to withstand pressure and an optimistic attitude at a young age.
      `
    }
  ],
  zh: [
    {
      name: 'Jessica',
      image: jessicaAvatar,
      title: '创办人',
      designation: true,
      qualifications: [
        'Gravity Coach咨询工作室创始人',
        'Gravity教育科技公司联合创始人',
        '约翰霍普金斯大学教育学硕士；社会学硕士；在读心理学博士'
      ],
      bio: `
      在十几年英文教育和留学规划工作中，遇见了许多可可爱爱的学生，也了解到他们形形色色的困扰。常常在想，“培养人就是培养他对前途的希望”，所以作为教育工作者，除了教授知识和技能，也应该注重学生的未来规划和心理健康。<br>
      <br>
      除了学生，工作中也同时接触到许多焦虑和期翼交织的家长。太过靠近会让孩子抵触，太过放手又心有不安。有时候，家长同样需要被关心和安抚。<br>
      <br>
      所以有了“万有引力”这个由艺术家，儿童教师，英文教授，教育专家，企业高管，心理学专家组成的集体；团队跨越中国，美国，澳洲，英国，法国，新加坡，加拿大。我们确保每一个来到万有引力的学生，不管是儿童/青少年英文学习，留学指导，面试培训，职业技能提高，还是教育路线设计，内心成长，职业前景思考与规划，都能得到最专业，最全面的指引和回答；也确保每位来到“万有引力”的家长，都能够得到最及时的信息反馈，听到最先进的教育理念，与孩子携手同行，一起成长。
      `
    },
    {
      name: 'Mr. Mac',
      image: macAvatar,
      qualifications: [
        '英/美国著名艺术家',
        '阴影艺术流派创始人',
        '波洛克/克拉斯纳基金会奖获奖人',
        '纽约州立大学杰出教授'
      ],
      bio: `
      作品被三十五余博物馆收购，包括纽约现代艺术博物馆，蓬皮杜艺术中心，巴黎艺术博物馆，卢森堡现代艺术博物馆等<br>
      喜欢了解新的艺术潮流，最近发布了NFT作品
      `
    },
    {
      name: 'Ms. Susan',
      image: susanAvatar,
      qualifications: [
        '20+ IB小学项目（IB PYP）儿童教育经验',
        '15年以上教授/培训/认证幼儿教师经验'
      ],
      bio: `
      Susan 女士在 IB PYP 方法和探究式学习方面拥有超过 20 年的教学和指导其他教师的经验。 在我们的儿童英语培训班中，我们使用她的方法和课程材料让您的孩子比以往任何时候都更有效地学习。
      `
    },
    {
      name: 'Ms. Erinn',
      image: erinnAvatar,
      qualifications: [
        '三十年儿童英文/艺术/地理教学经验',
        '教师的礼物教学机构CEO',
        '美国家庭合作学校教师以及负责人',
        '幼儿教育学士 教育科学硕士'
      ],
      bio: `
      有两个可爱的儿女<br>
      嫁给一个勇敢的消防员<br>
      热爱教育和周游世界<br>
      <br>
      擅长用科学和生动形象的方式教学，有丰富的网上教学经验，教授过上千名儿童，能够有效让孩子在电脑前集中精力，获取知识。<br>
      擅长用独特的自创教学体系，将英文，语法以及自然，地理知识巧妙结合，全方面的为孩子提供最有价值的课程。<br>
      `
    },
    {
      name: 'Ms. Lena',
      image: lenaAvatar,
      qualifications: [
        '十二年儿童以及青少年教学经验',
        '美国公立学校教师',
        '儿童教育以及戏剧艺术学士 教育硕士'
      ],
      bio: `
      擅长用道具，声音模仿等各种方式让学生记忆深刻；学前班教学中，用到的是多年教学经验总结的“音乐”教学方式，让孩子们寓教于乐的同时，将重要内容熟记于心；小学教学中，则擅长用举例，讨论，反馈，评价的方式，引导孩子的思维方式，锻炼孩子的思考能力，同时将难度高又枯燥的内容用科学的方式讲解给学生，在学生群体里获得了非常大的成功。
      `
    },
    {
      name: 'Mr. John',
      image: defaultAvatar,
      qualifications: [
        '“Right Direction”教育集团高级经理人',
        'Code Legend职业培训公司CPO',
        '投资人',
        '美国私立大学前招生官',
        '范德堡大学教育学学士 传播学/管理学硕士'
      ],
      bio: `
      三十多年教育领域领导以及留学主申人经验，擅长各种“疑难”留学申请，擅长一针见血的指出问题，帮助学生设计最科学的教育路线，申请方式以及未来职业规划。
      `
    },
    {
      name: 'Ms. Marina',
      image: marinaAvatar,
      qualifications: [
        '哈佛大学荣誉员工',
        '哈佛大学机械与科技学院科研部副主管',
        '哈佛医学院医疗保险部金融经理',
        '波士顿大学经济学院科研奖金管理经理'
      ],
      bio: ``
    },
    {
      name: 'Ms. Cathy',
      image: cathyAvatar,
      qualifications: [
        '美国著名教育家 艺术家',
        'Fulbright Senior Research学者',
        '蒙特克莱尔州立大学教授'
      ],
      bio: `
      作品多次在国际展览中展出<br>
      建立全球艺术家作品巡展项目，与上海，日本，加拿大等艺术家合作举办展览
      `
    },
    {
      name: 'Mr. Clark',
      image: clarkAvatar,
      qualifications: [
        `纽约“Learner’s Academy”SAT教学班创始人`,
        '多伦多Enchaned课外辅导学院顾问',
        '英国曼彻斯特大学大学英文文学学士，教育学硕士'
      ],
      bio: `
      用独创的SAT，ACT教学方法，帮助学生发现问题，提高成绩。教授英语三十多年以来，教授上千名学生，平均成绩提高百分之七十。同时擅长高级英文写作以及英美文学解析，用风趣形象的语言和清晰准确的讲解，帮助学生有效提高对英文的深度理解水平。
      `
    },
    {
      name: 'Mr. Daniel',
      image: danielAvatar,
      qualifications: [
        'Gravity教育科技公司创始人',
        'Swaglicious科技公司CEO',
        '纽约州立大学艺术系前客座教授',
        'SI高级人才管理公司CTO',
        'Solar资源公司科技顾问'
      ],
      bio: `
      从视觉艺术专业转行到计算机编程工作，再到科技公司管理职位，每一次转变都离不开优秀和长远的职业规划。因此作为万有引力职业规划顾问，为学生们提供了精准的职业技能分析，以及未来职业发展路线设计。作为康奈尔大学校友，也经常义务为学生们解答常春藤学校相关问题，比如校园文化，校园安全，教学风格等。      
      `
    },
    {
      name: 'Mr. Nick',
      image: nickAvatar,
      qualifications: [
        'UC伯克利大学哈斯商学院工商管理与计算机工程双学位',
        'UIUC计算机工程硕士',
        '伯克利咨询公司前高级主管',
        '亚马逊公司高级编程师'
      ],
      bio: `
      `
    },
    {
      name: 'Mr. Salomon',
      image: salomonAvatar,
      qualifications: [
        '宾夕法尼亚大学生物/化学双学位',
        '曾在纽约大学，宾大授课',
        '前宾大研究员',
        '华盛顿特区教育局儿童行为学教育指导',
        '出版书籍“机会指数不公和环境不公”',
        '人工技能咨询公司高级数据分析师'
      ],
      bio: `
      `
    },
    {
      name: 'Mr. Teddy',
      image: teddyAvatar,
      qualifications: [
        '三一学校优秀毕业生 （全美顶尖私立学校）',
        '布朗大学荣誉毕业生 计算机学士',
        '青年慈善家',
        '亚马逊高级编程师'
      ],
      bio: ``
    },
    {
      name: 'Ms. Julia',
      image: juliaAvatar,
      qualifications: [
        '美国银行前高级项目经理',
        '新南威尔士大学工商管理硕士',
        '澳洲麦考瑞大学经济学学士'
      ],
      bio: `
      从人大附中开始培养的学习能力和严谨态度，成为工作和教学中一生受益的能力。擅长数学，经济，金融相关课程辅导，讲解清晰，方法独特，让学生们快速理解并找到方法；擅长澳洲大学申请，作为理科学霸，为学生们在升学，国际学校学习以及申请道路上排除各种拦路虎。
      `
    },
    {
      name: 'Ms. Jade',
      image: jadeAvatar,
      qualifications: [
        '十四年跨国公司销售运营以及项目主管',
        'Fintech项目经理',
        '清华大学MBA PMP',
        '法国里昂三大热力资源管理专业'
      ],
      bio: `
      金融与管理方面拥有深厚行业经验。曾经在新加坡，法国，美国等各国工作和学习，给学生们带来了国际化的视角和精准的专业选择建议以及职业选择建议。同时作为托福满分学霸，为学生们提供了英文学习最有效的方法。坚持给予学生最直接，最实用，最诚实的答案，是学生们敬佩的犀利导师。
      `
    },
    {
      name: 'Mr. Fengyuan',
      image: defaultAvatar,
      qualifications: [
        '德州大学奥斯丁金融学荣誉毕业生',
        'Tufts大学硕士荣誉毕业生',
        '前科学杂志职业编辑',
        '国际职业经理人'
      ],
      bio: `
      `
    },
    {
      name: 'Ms. Jennifer',
      image: jenniferAvatar,
      qualifications: [
        '纽约地方教育局青少年心理咨询教师',
        'Life Coach认证',
        '纽约中小学教师培训导师'
      ],
      bio: `
      工作中熟知儿童以及青少年升学压力，人际关系，同学矛盾，生活焦虑，文化差异等等各方面儿童以及青春期问题。用专业，科学以及亲切的态度，帮助学生走出思维困境。
      `
    },
    {
      name: 'Ms. Amelia',
      image: ameliaAvatar,
      qualifications: [
        '“晓茁”儿童美育工作室创始人',
        '北京春晓伟业图书发行有限公司首席设计人',
        '中科院儿童发展与教育心理硕士',
        'Life Coach国际认证教练',
        '绘画心理分析与疗愈师'
      ],
      bio: `
      相信“地才”也可与“天才”比肩<br>
      相信心理咨询师的天赋来自于对职业的热爱<br>
      喜欢和孩子们在一起的每段时光<br>
      <br>
      既是咨询室里专业严谨的咨询师，也是孩子们信任和喜爱的大姐姐。擅长在教授孩子绘画，一起谈笑玩乐中，用专业知识分析儿童心理，帮助每一个孩子树立优秀的学习态度和健康的心理。及时发现儿童行为问题，让孩子们在幼小的年纪就培养出强大的抗压能力和乐观向上的态度。
      `
    }
  ]
}

export default profiles;