import { useContext } from 'react';
import { Context } from '../../DataStore';
import strings from '../../data/strings';

import ReactHtmlParser from 'react-html-parser'; 

import SectionTitle from '../common/SectionTitle';

import '../../styles/composite/Methodology.scss';

const Methodology = () => {
  const { store } = useContext(Context);
  const text = strings[store.lang].Methodology;

  return (
    <div id={text.header.replace(' ', '')} className="Methodology">
      <SectionTitle text={text.header} />

      <div className="testimonial">
        {ReactHtmlParser(`Dear my teachers,
        <br><br>
        Here at first, I want to show my greatest thanks to all of you. I clearly know that without your kind help and teaching, it could be a much more difficult task for me to do.:) So, the offers from universities are not only congratulations for me, but also congratulations for all of you! ^-^ I feel very thankful to work with you in the applicaiton process.:)
        <br><br>
        For John, thank you for your kind and caring help!! I still can recall the first questionnaire you made for me. It made me know that Business can be the best major for me to do. Without your smart help, it would be difficult for me to know this. This is a small part of all your help to me, and i feel so thankful for your patience on my application essays and the entire application process! Your help to me is significant! It's so nice to know you! :)
        <br><br>
        For Jessica, i feel very thankful for your teaching and help to my summer session, SAT, application, and interviews!!! From your teaching and our conversations, I think you are a very charming and speical lady because of your patience and caring. Your kind words to me make me feel very confident and optimistic to my future! I hope we can be not only teacher and studeent, but also friends!~~  
        <br><br>
        Anyway, i think that we did a excellent job together! And i feel extremely pleasant and thankful to have all of you in our team~~! There are so many things of myself that need to be improved, but you still show care and patience. I know that there are too many thanks in this letter, but it is really the word i want to say to you. Thank you!! :)Congratulations to all of us!!
        <br><br>
        Have a nice day.^-^
        <br>
        Best regards`
        )}
      </div>
    </div>
  );
}

export default Methodology;
