import { useState } from 'react';

import '../../styles/common/Link.scss';

const Link = (props) => {
  const [hovering, setHovering] = useState(false);

  const scrollIntoView = (id) => {
    const header = document.getElementById(id);
    header.scrollIntoView();
  }

  return (
    <div className="Link">
      <button
        className={`buttonLink${hovering ? ' hover' : ''}${props.filled ? ' filled' : ''}`}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={() => scrollIntoView(props.text.replace(' ', ''))}>
        {props.text}
      </button>
    </div>
  );
}

export default Link;
