import React from "react";

export const state = {
  lang: 'en'
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "store":
      return action.data
    default:
      return {
        ...state,
        [action.type]: action.data
      }
  }
};

export const Context = React.createContext();
